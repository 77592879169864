$inter-tight: Inter Tight;
$inter: Inter;

$title3-font-size: 2rem;
$title3-font-weight: 400;

$body1-font-size: 1rem;
$body1-font-weight: 400;

$label1-font-size: 1rem;
$label1-font-weight: 500;

$label2-font-size: 0.875rem;
$label2-font-weight: 600;

p,
h1,
h2,
h3,
h4,
a,
div,
input,
span,
label, textarea {
  line-height: 150%;
  font-style: normal;
  margin: 0;

  &.heading {
    font-weight: 300;
    font-size: 4rem;
  }

  &.title1 {
    font-size: 3rem;
    font-weight: 400;
  }
  &.title2 {
    font-size: 2.5rem;
    font-weight: 400;
  }

  &.title3 {
    font-size: $title3-font-size;
    font-weight: $title3-font-weight;
  }
  &.subtitle1 {
    font-size: 1.5rem;
    font-weight: 400;
  }
  &.subtitle2 {
    font-size: 1.25rem;
    font-weight: 600;
  }
  &.subtitle3 {
    font-size: 1rem;
    font-weight: 600;
  }

  &.label1 {
    font-size: $label1-font-size;
    font-weight: $label1-font-weight;
  }
  &.label2 {
    font-size: $label2-font-size;
    font-weight: $label2-font-weight;
  }

  &.body1 {
    font-family: $inter;
    font-size: $body1-font-size;
    font-weight: $body1-font-weight;
  }

  &.body2 {
    font-family: $inter;
    font-size: 0.875rem;
    font-weight: 500;
  }
  &.caption {
    font-family: $inter;
    font-size: 0.6875rem;
    font-weight: 500;
  }
  &.balloon {
    font-family: $inter;
    font-size: 1.083;
    font-weight: 500;
    line-height: 130%;
  }
  &:link,
  .link {
    font-family: $inter;
    font-weight: 700;
    text-decoration: inherit;
    cursor: pointer;
  }
}
