// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@import "styles-variables.scss";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$consultantportal-primary: mat.define-palette(mat.$indigo-palette);
$consultantportal-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$consultantportal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$consultantportal-theme: mat.define-light-theme(
  (
    color: (
      primary: $consultantportal-primary,
      accent: $consultantportal-accent,
      warn: $consultantportal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($consultantportal-theme);

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "designSystem/styles-colors.scss";
@import "designSystem/device-sizes.scss";
@import "designSystem/styles-typography.scss";

// Typography
html {
  height: 100%;
}
body {
  font-family: "Inter Tight", "Inter";
  margin: 0;
  height: 100%;
  div {
    box-sizing: border-box;
  }
}
.label2,
.body1,
.body2,
.caption {
  font-family: "Inter", "Inter";
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}
h1 {
  font-weight: 500;
  font-size: 2.7rem;
  line-height: 2.8rem;
}
// html,
// body {
//   height: 100%;
// }
// body {
//   margin: 0;
//   color: $dark;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;

a:link,
.link {
  color: $primary_text;
}

//   a:visited {
//     text-decoration: inherit;
//     color: inherit;
//     font-weight: 600;
//   }
//   // a.link {
//   //   font-weight: 500;
//   //   cursor: pointer;
//   //   color: $primary;
//   // }
//   textarea:focus,
//   // input:focus {
//   //   outline: unset;
//   //   border: 2px solid $primary;
//   // }
//   // input::-webkit-search-cancel-button {

//   // }
//   p{
//     margin: 0;
//   }
//   input[type="search"]::-webkit-search-cancel-button {
//     -webkit-appearance: none;
//     display: inline-block;
//     width: 10px;
//     height: 10px;
//     margin-left: 10px;
//     cursor: pointer;
//     background: linear-gradient(
//         45deg,
//         rgba(0, 0, 0, 0) 0%,
//         rgba(0, 0, 0, 0) 43%,
//         $dark 45%,
//         $dark 55%,
//         rgba(0, 0, 0, 0) 57%,
//         rgba(0, 0, 0, 0) 100%
//       ),
//       linear-gradient(
//         135deg,
//         transparent 0%,
//         transparent 43%,
//         $dark 45%,
//         $dark 55%,
//         transparent 57%,
//         transparent 100%
//       );
//   }
//   .description {
//     font-weight: 400;
//     color: rgba($color: $dark, $alpha: 0.8);
//     line-height: 142.8%;
//     letter-spacing: -0.01em;
//   }

//   .baseline_xlarge {
//     margin: 0;
//     font-size: 1.8rem;
//     @media ($laptop) {
//       font-size: 1.6rem;
//     }
//     line-height: 1.8rem;
//   }
//   .body2 {
//     font-size: 1.125rem;

//     @media ($laptop) {
//       font-size: 0.9rem;
//     }
//     line-height: 1.5rem;
//   }
//   .baseline_small {
//     margin: 0;
//     font-size: 0.9rem;
//     @media ($laptop) {
//       font-size: 0.8rem;
//     }
//   }
//   p,
//   .body2 {
//     &.link {
//       font-weight: 500;
//       cursor: pointer;
//       color: $primary;
//     }
//     &.bold {
//       font-weight: 600;
//     }
//     &.bolder {
//       font-weight: 700;
//     }
//     &.light-bold {
//       font-weight: 500;
//     }
//   }
//   h3 {
//     font-weight: 500;
//     margin: 0;
//     font-size: 1.3rem;
//     line-height: 1.8rem;
//     font-weight: 500;
//     @media ($laptop) {
//       font-size: 1.1rem;
//     }
//   }
//   h2 {
//     font-size: 1.7rem;
//     @media ($laptop) {
//       font-size: 1.5rem;
//     }
//     line-height: 40px;
//     font-weight: 500;
//     margin: 0;
//   }
//   h1 {
//     font-weight: 500;
//     font-size: 2.2rem;
//     margin: 0;
//     line-height: 2.3rem;
//     @media ($laptop) {
//       font-size: 2rem;
//     }
//     &.lighter {
//       font-weight: 300;
//       line-height: 2.5rem;
//     }
//   }
//   ul {
//     margin: 0;
//   }
//   div {
//     box-sizing: border-box;
//   }

//   // .agent {
//   //   width: 72px;
//   //   height: 72px;
//   //   @media ($laptop) {
//   //     width: 64px;
//   //     height: 64px;
//   //   }
//   //   img {
//   //     height: 100%;
//   //     border-radius: 100px;
//   //   }
//   // }
//   .navigation-btn {
//     display: flex;
//     gap: 20px;
//     width: 100%;
//     > div:not(.btn) {
//       flex: 1 1 auto;
//     }
//     .btn {
//       @media ($phone) {
//         // min-width: unset;
//         // width: calc(50%);
//         // padding: 14px 0;
//       }
//     }
//   }
//   .btn {
//     border-radius: 5px;
//     border: 1px solid rgba($color: $dark, $alpha: 0.2);
//     padding: 10px 50px;
//     border-radius: 90px;
//     display: flex;
//     justify-content: center;
//     width: min-content;
//     align-items: center;
//     cursor: pointer;
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -khtml-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     min-width: 165px;
//     white-space: nowrap;
//     &:hover {
//       border: 1px solid $primary;
//       color: $primary;
//     }
//     .desc-text {
//       white-space: normal;
//     }
//     &.btn-select {
//       border: 0;
//       border-radius: 8px;
//       background-color: $lightGray;
//       padding: 10px 0;
//       padding-right: 50px;
//       justify-content: flex-start;
//       &:hover:not(.btn-dark) {
//         background-color: $lightGray;
//         color: $primary;
//         .checkmark {
//           border-color: $primary;
//         }
//       }
//     }
//     .checkmark {
//       border: 2px solid #e3e2e1;
//     }
//     &.btn-dark {
//       background-color: $primary;
//       color: white;
//       &:hover {
//         opacity: 0.7;
//       }
//       .checkmark {
//         border-color: $primary;
//       }
//     }
//     &.btn-light {
//       background-color: rgba($color: white, $alpha: 0.9);
//       color: $primary;
//       .checkmark {
//         border-color: white;
//       }
//     }
//     &.disable {
//       pointer-events: none;
//       opacity: 0.2;
//     }
//     input {
//       margin: 0 10px;
//       margin-top: -2px;
//     }
//   }
//   .checkState {
//     span {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       border-radius: 4px;
//       height: 20px;
//       width: 14px;
//     }
//   }

//   .checkState {
//     span::after {
//       content: "";
//       width: 5px;
//       height: 14px;
//       margin-top: -4px;
//       border: solid $primary;
//       border-width: 0 2px 2px 0;
//       // display: block;
//       -webkit-transform: rotate(45deg);
//       -ms-transform: rotate(45deg);
//       transform: rotate(45deg);
//     }
//   }

//   // .ngx-slider {
//   //   margin-left: -16px;
//   //   .ngx-slider-selection {
//   //     background: orange;
//   //   }
//   //   .ngx-slider-pointer {
//   //     &:hover {
//   //       box-shadow: 0px 2px 14px 0px #22222240;
//   //       background-color: $dark;
//   //       border: 2px solid $dark;
//   //       &:after {
//   //         background: white;
//   //         box-shadow: 0 4px 0 0 white, 0 8px 0 0 white;
//   //       }
//   //     }
//   //     cursor: pointer;
//   //     width: 18px;
//   //     height: 28px;
//   //     top: -14px;
//   //     background-color: white;
//   //     z-index: 3;
//   //     border-radius: 4px;
//   //     display: flex;
//   //     justify-content: center;
//   //     align-items: center;
//   //     margin-left: 6px;
//   //     border: 2px solid $lightGray;
//   //     box-shadow: 0px 2px 4px 0px #2222220d;

//   //     &.ngx-slider-active {
//   //       background-color: $dark;
//   //       border-color: $dark;

//   //       &::after {
//   //         background-color: white;
//   //         background: white;
//   //         box-shadow: 0 4px 0 0 white, 0 8px 0 0 white;
//   //       }
//   //     }

//   //     &:focus-visible {
//   //       outline: unset;
//   //     }
//   //     &:after {
//   //       border-radius: 0;
//   //       content: '';
//   //       position: absolute;
//   //       width: 80%;
//   //       height: 2px;
//   //       left: 50%;
//   //       top: unset;
//   //       transform: rotate(90deg) translateY(calc(-50% + 4px));
//   //       background: lightGray;
//   //       box-shadow: 0 4px 0 0 lightGray, 0 8px 0 0 lightGray;
//   //     }
//   //   }

//   //   .ngx-slider-inner-tooltip {
//   //     display: none;
//   //   }
//   //   .ngx-slider-span {
//   //     cursor: pointer;
//   //   }
//   //   .ngx-slider-bar {
//   //     height: 2px;
//   //     width: calc(100% - 20px);
//   //     left: 16px;
//   //     background-color: $lightGray;
//   //   }
//   //   .ngx-slider-tick {
//   //     border-radius: unset;
//   //     height: 16px;
//   //     width: 2px;
//   //     top: -4px;
//   //     margin-left: 16px;
//   //     background-color: orange;
//   //     font-size: 0.8rem;
//   //     .ngx-slider-tick-legend {
//   //       white-space: nowrap;
//   //       display: flex;
//   //       justify-content: flex-start;
//   //     }
//   //     &:last-of-type {
//   //       .ngx-slider-tick-legend {
//   //         justify-content: flex-end;
//   //         left: -20px;
//   //       }
//   //     }
//   //     &:first-of-type {
//   //       .ngx-slider-tick-legend {
//   //         left: 22px;
//   //       }
//   //     }
//   //   }
//   // }
// }
// // .mat-slide-toggle {
// //   .mat-slide-toggle-bar {
// //     height: 24px !important;
// //     width: 44px !important;
// //     border-radius: 34px !important;
// //   }
// //   .mat-slide-toggle-thumb-container {
// //     top: 3px !important;
// //     left: 3px !important;
// //     .mat-slide-toggle-thumb {
// //       height: 18px !important;
// //       width: 18px !important;
// //     }
// //   }
// //   &.mat-checked {
// //     .mat-slide-toggle-bar {
// //       background-color: $primary !important;
// //     }
// //     .mat-slide-toggle-thumb {
// //       background-color: white !important;
// //     }
// //     .mat-slide-toggle-thumb-container {
// //       left: 7px !important;
// //     }
// //     .mat-ripple-element {
// //       background-color: $primary !important;
// //     }
// //   }
// // }
// // .mat-slider {
// //   width: 100%;
// //   &.black {
// //     height: 10px;

// //     .mat-slider-thumb-container {
// //       > div {
// //         background: #ffffff !important;
// //         border: 2px solid #dddddd !important;
// //         box-shadow: 0px 2px 12px rgba(34, 34, 34, 0.12) !important;
// //         border-radius: 100px;
// //         width: 28px;
// //         height: 28px;
// //         bottom: unset;
// //         right: -16px;
// //         // transform: unset;
// //       }
// //     }
// //     .mat-slider-wrapper {
// //       height: 6px !important;
// //       right: 0px !important;
// //       left: 0px !important;
// //       top: 0 !important;
// //       > div:not(.mat-slider-thumb-container) {
// //         border-radius: 6px;
// //         height: 6px !important;
// //         > div {
// //           border-radius: 6px;

// //           height: 10px !important;
// //         }
// //       }
// //       .mat-slider-thumb-container {
// //         height: 6px !important;
// //         top: unset !important;
// //         display: flex;
// //         align-items: center;
// //       }

// //       .mat-slider-track-wrapper {
// //         background: linear-gradient(90deg, $primary 99%, $lightGray 1%);

// //         .mat-slider-track-background {
// //           background-color: $lightGray !important;
// //           border-top-left-radius: 0;
// //           border-bottom-left-radius: 0;
// //         }
// //         .mat-slider-track-fill {
// //           background-color: transparent !important;
// //         }
// //       }
// //     }
// //   }
// //   &.gradient {
// //     .mat-slider-thumb-container {
// //       > div {
// //         background: #ffffff !important;
// //         border: 2px solid #dddddd !important;
// //         box-shadow: 0px 2px 12px rgba(34, 34, 34, 0.12) !important;
// //         border-radius: 100px;
// //         width: 28px;
// //         height: 28px;
// //         bottom: unset;
// //         right: -10px;
// //         // transform: unset;
// //       }
// //     }
// //     .mat-slider-wrapper {
// //       height: 16px !important;
// //       right: 0px !important;
// //       > div:not(.mat-slider-thumb-container) {
// //         border-radius: 10px;
// //         height: 16px !important;
// //         > div {
// //           border-radius: 10px;
// //           height: 16px !important;
// //         }
// //       }
// //       .mat-slider-thumb-container {
// //         height: 16px !important;
// //         top: unset !important;
// //         display: flex;
// //         align-items: center;
// //       }

// //       .mat-slider-track-wrapper {
// //         background: linear-gradient(
// //           270deg,
// //           #fe635c -29.47%,
// //           #ffc534 9.64%,
// //           #6f5dc2 80.44%
// //         );
// //         -webkit-clip-path: polygon(0 45%, 100% 0, 100% 100%, 0 55%);
// //         clip-path: polygon(0 45%, 100% 0, 100% 100%, 0 55%);

// //         .mat-slider-track-background {
// //           background-color: $lightGray !important;
// //           border-top-left-radius: 0;
// //           border-bottom-left-radius: 0;
// //         }
// //         .mat-slider-track-fill {
// //           background-color: transparent !important;
// //         }
// //       }
// //     }
// //     .mat-select-panel-wrap {
// //       .line {
// //         display: block;
// //         height: 2px;
// //         width: calc(100% - 10px);
// //         background-color: $lightGray;
// //         margin: auto;
// //       }
// //     }
// //     .mat-select {
// //       position: absolute;
// //       top: 50%;
// //       transform: translateY(-50%);
// //       visibility: hidden;
// //     }
// //     .mat-form-field-appearance-legacy {
// //       .mat-form-field-underline {
// //         display: none;
// //       }
// //     }
// //   }
// // }
// @keyframes display {
//   0% {
//     display: none;
//   }
//   100% {
//     display: flex;
//   }
// }

// @keyframes open {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
// @keyframes close {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//     position: absolute;
//   }
//   100% {
//     opacity: 0;
//     position: unset;
//   }
// }
// @keyframes fadeInToggle {
//   0% {
//     opacity: 0;
//     position: unset;
//   }
//   100% {
//     opacity: 0.6;
//     position: unset;
//   }
// }
// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//     position: unset;
//   }
//   100% {
//     opacity: 1;
//     position: unset;
//   }
// }
// @keyframes fadeInOut {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
