// Light mode colors

//Default
$default_background: rgba(255, 255, 255, 1);
$default_background-hover: rgba(236, 243, 255, 1);
$default_onBackground: rgba(41, 42, 49, 1);
$default_stroke: rgba(200, 200, 208, 1);
.default {
  &.background {
    background-color: $default_background;
  }
  &.background-hover {
    background-color: $default_background-hover;
  }
  &.on-background {
    background-color: $default_onBackground;
  }
  &.stroke {
    background-color: $default_stroke;
  }
}

//Primary
$primary_background: rgba(67, 86, 255, 1);
$primary_background-hover: rgba(54, 58, 245, 1);
$primary_onBackground: rgba(255, 255, 255, 1);
$primary_text: rgba(54, 58, 245, 1);
$primary_stroke: rgba(54, 58, 245, 1);
$primary_stroke-active: rgba(156, 182, 255, 1);

.primary {
  &.background {
    background-color: $primary_background;
  }
  &.background-hover {
    background-color: $primary_background-hover;
  }
  &.on-background {
    background-color: $primary_onBackground;
  }
  &.text {
    background-color: $primary_text;
  }
  &.stroke {
    background-color: $primary_stroke;
  }
  &.stroke-active {
    background-color: $primary_stroke-active;
  }
}

//Secondary
$secondary_background: rgba(194, 212, 255, 1);
$secondary_background-hover: rgba(117, 141, 255, 1);
$secondary_onBackground: rgba(41, 42, 49, 1);

.secondary {
  &.background {
    background-color: $secondary_background;
  }
  &.background-hover {
    background-color: $secondary_background-hover;
  }
  &.on-background {
    background-color: $secondary_onBackground;
  }
}

//Success
$success_background: rgba(213, 255, 233, 1);
$success_onBackground: rgba(41, 42, 49, 1);
$success_text: rgba(4, 119, 59, 1);
$success_shape: rgba(0, 194, 84, 1);

.success {
  &.background {
    background-color: $success_background;
  }
  &.on-background {
    background-color: $success_onBackground;
  }
  &.text {
    background-color: $success_text;
  }
  &.shape {
    background-color: $success_shape;
  }
}

//Error
$error_background: rgba(255, 227, 213, 1);
$error_onBackground: rgba(41, 42, 49, 1);
$error_text: rgba(251, 102, 60, 1);
$error_shape: rgba(251, 102, 60, 1);

.error {
  &.background {
    background-color: $error_background;
  }
  &.on-background {
    background-color: $error_onBackground;
  }
  &.text {
    background-color: $error_text;
  }
  &.shape {
    background-color: $error_shape;
  }
}

//Elevation
$elevation_default: rgba(245, 245, 245, 1);
$elevation_primary: rgba(117, 141, 255, 0.07);
$elevation_onBackground: rgba(41, 42, 49, 1);

.elevation {
  &.default {
    background-color: $elevation_default;
  }
  &.primary {
    background-color: $elevation_primary;
  }
  &.on-background {
    background-color: $elevation_onBackground;
  }
}

[contenteditable] {
  outline: 0px solid transparent;
}
