@import "styles-colors.scss";
@import "device-sizes.scss";
@import "styles-typography.scss";
@import "https://cdn.jsdelivr.net/npm/@tabler/core@1.0.0-beta17/dist/css/tabler-flags.min.css";

i-tabler {
  stroke-width: 1.5px !important;
}
//wrap step selection
.wrap-selection {
  display: inline-flex;
  flex-direction: column;
  gap: 64px;
  min-width: 444px;
  width: 100%;
  @media ($large-phone) {
    min-width: unset;
  }
  .label2 {
    display: flex;
    align-items: center;
    > span {
      margin-left: 8px;
      text-transform: capitalize;
      display: flex;
      padding: 4px 10px;
      background-color: rgba($color: $elevation_primary, $alpha: 0.1);
      border-radius: 12px;
    }
  }
}

//Input

.field,
.box,
.select,
.list,
.textarea,
.calendar {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &.disabled {
    pointer-events: none;
    color: rgba($color: $default_onBackground, $alpha: 0.4);
    label {
      color: rgba($color: $default_onBackground, $alpha: 0.4);
    }
  }
  ::placeholder {
    color: rgba($color: $default_onBackground, $alpha: 0.4);
  }
  label {
    font-weight: 600;
    font-size: 14px;
    color: $default_onBackground;
    &.disabled {
      color: rgba($color: $default_onBackground, $alpha: 0.4);
    }
  }
  textarea {
    height: 81px;
    resize: none;
    outline: unset;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 0 1px inset rgb(200, 200, 208);
    justify-content: center;
    padding: 8px;
    line-height: 150%;
    align-items: center;
    width: calc(100% - 16px);
  }
  > div {
    display: flex;
    align-items: center;
    position: relative;
    i-tabler {
      position: absolute;
      right: 8px;
    }
    .leadingIcon {
      padding-left: 40px;
      i-tabler:nth-child(1) {
        left: 8px;
      }
    }
  }
  position: relative;
  .mat-datepicker-toggle {
    position: absolute;
  }
  &.combined {
    > div {
      > i-tabler {
        border-radius: 4px;
        box-shadow: 0 0 0 1px inset $default_stroke;
        position: unset;
        padding: 8px 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1px;
        display: flex;
      }
    }
  }
  input,
  .input {
    outline: unset;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 0 1px inset $default_stroke;
    justify-content: center;
    padding: 8px;
    line-height: 150%;
    align-items: center;
    width: calc(100% - 16px);
    &.trailingIcon {
      padding-right: 40px;
    }
    // min-height: 40px;
    &:focus,
    &.active {
      box-shadow: 0 0 0 2px inset $primary_stroke;
    }
    &:hover {
      &:not(:disabled) {
        background-color: $elevation_primary;
      }
    }
    &:disabled {
      color: rgba($color: $default_onBackground, $alpha: 0.4);
    }
  }
}
.box {
  flex-direction: row;
  gap: 40px;
  input {
    text-align: center;
    border-radius: 8px;
    width: 100px;
    height: 152px;
  }
}
.combined {
  > div {
    display: flex;
  }

  &.leading {
    .input {
      margin-right: -1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.trailing {
    .input {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
.list {
  > div {
    position: relative;
    width: fit-content;
    .search-input {
      display: flex;
      align-items: center;
      width: 100%;
      i-tabler {
        pointer-events: none;
      }
      input,
      .input {
        background-color: white;
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: space-between;
        .innerText {
          height: 24px;
          min-width: 80px;
        }
        .trailingIcon {
          padding-right: 40px;
        }
        .leadingIcon {
          padding-left: 40px;
        }
        .empty {
          width: 280px;
          height: 24px;
        }
        > div {
          // padding-right: 30px;
          white-space: nowrap;
          display: flex;
          gap: 8px;
          align-items: center;
        }
        span {
          color: rgba($color: $default_onBackground, $alpha: 0.4);
        }
      }
    }
    .dropdown {
      max-height: 400px;
      overflow-y: auto;
      border-radius: 8px;
      width: 100%;
      background-color: white;
      top: 42px;
      position: absolute;
      box-shadow: 0px 8px 24px 0px
        rgba($color: $default_onBackground, $alpha: 0.15);
      display: flex;
      flex-direction: column;
      z-index: 1;
      background-color: white;
      padding: 0 8px;
      > div {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        &:hover {
          background-color: rgba($color: $default_background-hover, $alpha: 1);
        }
        cursor: pointer;
        padding: 16px 8px;
        width: 100%;
        border-top: 1px solid rgba($color: $default_background-hover, $alpha: 1);
        display: flex;
        gap: 8px;
        align-items: center;
        span {
          color: rgba($color: $default_onBackground, $alpha: 0.4);
        }
      }
    }
    .flag {
      box-shadow: 0px 4px 4px 0px
        rgba($color: $default_onBackground, $alpha: 0.15);
    }
  }
}
.select {
  display: flex;
  flex-direction: column;
  .wrap-select {
    position: relative;
    display: flex;
    align-items: center;
    i-tabler {
      right: 8px;
      position: absolute;
    }
  }
  .select-dropdown {
    position: relative;
    > div {
      display: flex;
      flex-direction: column;
      position: absolute;
      max-height: 344px;
      overflow: auto;
      box-shadow: 0px 8px 24px rgb(34 34 34 / 15%);
      background-color: white;
      z-index: 1;
      -webkit-appearance: none;
      outline: none;
      min-width: 150px;
      // position: relative;
      .split {
        border-top: 1px solid #e3e2e1;
        position: absolute;
        width: 100%;
        left: 0;
        margin-top: -20px;
      }
      > div {
        padding: 18px;
        &.selected {
          background-color: $primary_background;
          color: white;
        }
        &:hover {
          cursor: pointer;
          background-color: $primary_background-hover;
          color: white;
        }
        i {
          opacity: 0.5;
          font-size: 0.8rem;
        }
      }
    }
  }
}
.steps {
  flex-direction: column;
  display: inline-flex;
  gap: 4px;
  width: 100%;
  .bar {
    height: 6px;
    border-radius: 4px;
    background-color: $secondary_background;
    align-self: stretch;
    &.active {
      background-color: $default_onBackground;
    }
  }
  > label {
    text-transform: uppercase;
  }
}
.progress-bar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div {
    display: flex;
    gap: 4px;
    app-steps {
      width: 100%;
    }
  }
}

.navbar {
  display: inline-flex;
  align-items: center;
  width: 264px;
  padding: 8px 12px;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
  color: rgba($color: $default_onBackground, $alpha: 1);
  overflow: hidden;
  gap: 8px;
  min-height: 40px;
  @media ($large-phone) {
    width: 100%;
  }
  &.completed {
    background-color: rgba($color: $elevation_primary, $alpha: 0.1);
  }

  &.default {
    background-color: unset;
  }
  &.action {
    color: $primary_text;
  }
  &:hover {
    background-color: rgba($color: $elevation_default, $alpha: 0.1);
    color: rgba($color: $default_onBackground, $alpha: 1);
  }
  &.active {
    background-color: rgba($color: $default_onBackground, $alpha: 1);
    color: $primary_onBackground;
    &:hover {
      background-color: rgba($color: $default_onBackground, $alpha: 1);
      color: $primary_onBackground;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  &.no-hover {
    pointer-events: none;
  }
  display: flex;
  align-items: center;
  i-tabler {
    width: 24px;
    height: 24px;
  }
  span {
    width: 20px;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    .amount {
      backdrop-filter: blur(10px);
      background-color: $default_background;
      padding: 4px 8px;
      border-radius: 12px;
      color: $primary_text;
    }
    > div {
      white-space: nowrap;
    }
  }
}
.slider {
  width: 100%;
  // max-width: 440px;
  padding: 16px 12px;
  background-color: rgba($color: $elevation_default, $alpha: 1);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 8px;
  > div {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .caption {
        opacity: 0.4;
        text-transform: uppercase;
      }
    }
  }

  .mat-slider {
    width: 100%;
    &.default-slider {
      .mat-slider-thumb {
        transform: scale(1);
        background-color: $primary_background !important;
        border-color: $primary_background !important;
        height: 24px;
        width: 24px;
        right: -12px;
        bottom: -13px;
      }
      .mat-slider-wrapper {
        left: 0;
        right: 0;
      }
      .mat-slider-track-wrapper {
        background-color: unset;
        height: 5px;
        border-radius: 5px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        &::before,
        &::after {
          content: "";
          background-color: rgba($color: $primary_background, $alpha: 0.4);
          height: 5px;
          width: 5px;
          position: absolute;
          z-index: 1;
          border-radius: 5px;
        }
        &::after {
          left: unset;
          right: 0;
        }
        div:nth-child(1) {
          &::before {
            content: "";
            background-color: rgba($color: $primary_background, $alpha: 0.4);
            height: 5px;
            width: 5px;
            position: absolute;
            z-index: 1;
            border-radius: 5px;
            left: calc(50% - 2px);
          }
        }
        > div {
          display: flex;
          align-items: center;
          transform: unset !important;
          height: 5px;
          background-color: $secondary_background;
        }
      }
    }
  }
  &.disabled {
    pointer-events: none;
    .mat-slider {
      &.default-slider {
        .mat-slider-thumb {
          transform: scale(1);
          background-color: rgba(
            $color: $default_onBackground,
            $alpha: 1
          ) !important;
          border-color: rgba($color: $default_onBackground, $alpha: 1) !important;
        }
        .mat-slider-track-wrapper {
          // &::before,
          // &::after {
          //   background-color: rgba($color: $default_stroke, $alpha: 0.4);
          // }
          > div {
            background-color: rgba($color: $default_stroke, $alpha: 0.4);
          }
        }
      }
    }
  }
}
.widgets {
  .agent,
  .profile-strength {
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: right;
    > div {
      max-width: 172px;
      color: rgba($color: $default_onBackground, $alpha: 1);
      .caption {
        opacity: 0.4;
        margin-bottom: -1px;
      }
      .body2 {
        color: $primary_text;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    img {
      border-radius: 100%;
      height: 58px;
      width: 58px;
    }
    .circle {
      height: 74px;
      width: 74px;
      circle-progress {
        height: 74px;
        width: 74px;
        display: block;
        tspan {
          fill: rgba($color: $default_onBackground, $alpha: 1);
          font-size: 3rem;
          font-weight: $label1-font-weight;
        }
        path {
          stroke: rgba($color: $default_onBackground, $alpha: 1);
          stroke-width: 12px;
        }
        circle {
          stroke: rgba($color: $secondary_background, $alpha: 1);
        }
      }
    }
  }
}
.select-items {
  position: relative;
  display: flex;
  min-height: 24px;
  > div {
    display: flex;
  }
  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      height: 24px;
      width: 44px;
      display: flex;
      align-items: center;
      border-radius: 24px;
      background-color: rgba($color: $default_stroke, $alpha: 1);
      .mat-slide-toggle-thumb-container {
        position: unset;
        height: 18px;
        width: 18px;
        margin-left: 3px;
        .mat-slide-toggle-thumb {
          height: 18px;
          width: 18px;
        }
        .mat-ripple {
          display: none;
        }
      }
    }
    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: rgba($color: $primary_background, $alpha: 1);
      }
      .mat-slide-toggle-thumb {
        background-color: $default_background;
        box-shadow: 0;
      }
      .mat-slide-toggle-thumb-container {
        transform: translate3d(20px, 0, 0);
      }
    }
  }
  .radio {
    pointer-events: none;
    /* Customize the label (the container) */
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          box-shadow: 0 0 0px 2px inset
            rgba($color: $default_onBackground, $alpha: 1);
        }
        &:checked ~ .checkmark:after {
          display: block;
        }
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        box-shadow: 0 0 0px 2px inset rgba($color: $default_stroke, $alpha: 1);
        border-radius: 50%;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
        &:after {
          top: 5px;
          left: 5px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: rgba($color: $default_onBackground, $alpha: 1);
        }
      }
    }
  }
  //Custome checkbox
  .checkbox {
    pointer-events: none;
    .container {
      display: block;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          background-color: rgba($color: $default_onBackground, $alpha: 1);
          box-shadow: unset;
          i-tabler {
            color: white;
            display: inline-flex;
            stroke-width: 3px !important;
            width: 21px;
            height: 20px;
          }
        }
      }
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        box-shadow: 0 0 0px 2px inset rgba($color: $default_stroke, $alpha: 1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        i-tabler {
          display: none;
        }
      }
    }
  }
}
.buttons {
  cursor: pointer;
  display: inline-flex;
  padding: 12px;
  // gap: 8px;
  background-color: rgba($color: $elevation_default, $alpha: 1);
  border-radius: 4px;
  align-items: center;
  // min-height: 55px;
  &:hover {
    background-color: rgba($color: $elevation_primary, $alpha: 0.1);
  }
  &.active {
    background-color: $secondary_background;
    &:hover {
      background-color: $secondary_background-hover;
    }
  }
  &.description {
    min-width: 180px;
    align-items: flex-start;
    width: 100%;
  }
  &.disabled:not(.active) {
    pointer-events: none;
    opacity: 0.4;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 2px 0;
  }

  .checkmark {
    pointer-events: none;
  }
}
.month-year-select {
  position: relative;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .dropDown {
    position: absolute;
    background-color: white;
    padding: 18px 8px;
    padding-top: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 6px 18px rgba(34, 34, 34, 0.15);
    border-radius: 8px;
    z-index: 2;
    width: 100%;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    overflow: hidden auto;
    .year {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-content: space-between;
      padding-top: 18px;
      position: sticky;
      top: 0;
      background-color: rgba($color: white, $alpha: 1);
      width: calc(100% + 8px);
      p {
        padding: 6px 12px;
        background-color: rgba($color: $primary_background, $alpha: 0.05);
        border-radius: 3px;
        color: $primary_background;
        min-width: 60px;
      }
      > div {
        cursor: pointer;
      }
    }
    .months {
      display: flex;
      flex-direction: column;
      width: 100%;
      .month {
        &:hover {
          background-color: rgba($color: $elevation_default, $alpha: 0.1);
          color: $primary_background;
        }
        &.active {
          background-color: rgba($color: $elevation_default, $alpha: 0.1);
          color: $primary_background;
        }
        cursor: pointer;
        padding: 12px 8px;
        width: 100%;
        border-top: 1px solid
          rgba($color: rgba($color: $default_stroke, $alpha: 1), $alpha: 1);
      }
    }
  }
}
